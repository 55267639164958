import React, { useEffect, useState } from "react";
import { NextRouter, useRouter } from "next/router";
import { useAuth } from "@/hooks/useAuth";

import { ConvertAndView } from "@/containers/Converter/ConvertAndView";
import {
  IConversionType,
  IConverterContext,
} from "@/containers/Converter/types";
import { getConversionTypeFromPathname } from "@/containers/Converter/common/helpers/getConversionTypeOrFail";
import { SelectingFilePlaceholder } from "@/containers/Converter/UploadFile/components/SelectingFile/SelectingFilePlaceholder";
import { UploadFile } from "@/containers/Converter/UploadFile";
import { PreviewAndPayment } from "@/containers/Converter/PreviewAndPayment";

interface IProps {
  disabled: boolean;
  inheritConversionTypeFromRoute: boolean;
  formats: IConversionType[];
}

const getConversionType = (
  router: NextRouter,
  inheritConversionTypeFromRoute: boolean,
): IConversionType => {
  if (inheritConversionTypeFromRoute) {
    const typeFromPath = getConversionTypeFromPathname(router.pathname);
    if (typeFromPath) return typeFromPath;
  }

  const conversionType = router.query.type as IConversionType | undefined;
  return conversionType || "XD_TO_SKETCH";
};

export const ConverterContainer: React.FC<IProps> = ({
  disabled,
  inheritConversionTypeFromRoute,
  formats,
}) => {
  const [showConvertAndView, setShowConvertAndView] = useState(false);
  const router = useRouter();
  const { loggedIn, user, loading: loginInfoLoading } = useAuth();
  const conversionType = getConversionType(
    router,
    inheritConversionTypeFromRoute,
  );
  const [context, setContext] = useState<IConverterContext>({
    disabled,
    conversionType,
    formats,
    inheritConversionTypeFromRoute,
    loggedIn,
  });

  const showFileUpload = router.query.fileName === undefined;
  const showPreviewAndPayment = !showFileUpload && !showConvertAndView;

  useEffect(() => {
    // We need to ensure if this changes the state is also updated accordingly.
    if (disabled === context.disabled) return;
    setContext({ ...context, disabled });
  }, [disabled, context]);

  useEffect(() => {
    if (!loginInfoLoading && loggedIn) {
      setContext({ ...context, userEmail: user?.email, loggedIn });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <>
      {showFileUpload ? (
        loginInfoLoading ? (
          <SelectingFilePlaceholder formats={context.formats} />
        ) : (
          <UploadFile
            converterCtx={{
              ...context,
              loggedIn,
              userEmail: user?.email,
            }}
          />
        )
      ) : null}
      {showPreviewAndPayment && (
        <PreviewAndPayment
          converterCtx={context}
          onExit={(ctx, transition) => {
            if (transition === "goToConverter") {
              setShowConvertAndView(true);
              setContext({
                ...context,
                userEmail: ctx.user.email,
                uploadedFileName: ctx.uploadedFileName,
                fileDetails: ctx.fileDetails!,
                conversionType: ctx.conversionType,
              });
            }
          }}
        />
      )}
      {showConvertAndView && <ConvertAndView converterCtx={context} />}
    </>
  );
};
